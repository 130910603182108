import revive_payload_client_CEBlM6l5ns from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XupW4tJ4EL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zIixsMdbxX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9uiCCL8ieK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_W9dTbEx2m6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BNtkPcM8DM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kBJPfg3D98 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_R12fpNzJBg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4._3xdcoez42y22opag5ivt2vbtre/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_zv92SkVOE0 from "/opt/buildhome/repo/plugins/supabase.ts";
import vue_query_wrmMkNpEpe from "/opt/buildhome/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_CEBlM6l5ns,
  unhead_XupW4tJ4EL,
  router_zIixsMdbxX,
  payload_client_9uiCCL8ieK,
  navigation_repaint_client_W9dTbEx2m6,
  check_outdated_build_client_BNtkPcM8DM,
  chunk_reload_client_kBJPfg3D98,
  components_plugin_KR1HBZs4kY,
  prefetch_client_R12fpNzJBg,
  supabase_zv92SkVOE0,
  vue_query_wrmMkNpEpe
]