import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as landingwissFWelQ7Meta } from "/opt/buildhome/repo/pages/landing.vue?macro=true";
import { default as _91id_93w6aDFlz2DuMeta } from "/opt/buildhome/repo/pages/overview/[id].vue?macro=true";
import { default as indexJCNq5bvmmsMeta } from "/opt/buildhome/repo/pages/overview/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "landing",
    path: "/landing",
    meta: landingwissFWelQ7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/landing.vue")
  },
  {
    name: "overview-id",
    path: "/overview/:id()",
    component: () => import("/opt/buildhome/repo/pages/overview/[id].vue")
  },
  {
    name: "overview",
    path: "/overview",
    component: () => import("/opt/buildhome/repo/pages/overview/index.vue")
  }
]